import React, { memo, useEffect } from "react"
import PropTypes from "prop-types"
import { useFormikContext } from "formik"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { required } from "../../utils/validate"
import { useCampaignContext } from "./campaign.provider"
import Link from "../navigation/link.component"
import InputOfferCode from "./inputOfferCode.component"
import { Redirect } from "../structure/theme/styles.component"
import { Trans, useTranslation } from "../translation/translate.component"

function RedirectOtherProduct ({ href, children }) {
  return (
    <Redirect>
      <Link href={href} data-testid="other_product_offer_code">
        {children}
      </Link>
    </Redirect>
  )
}

RedirectOtherProduct.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

function InnerForm ({ customRender, focusInput = false }) {
  const { t } = useTranslation()
  const { origin, errorRedirectLink, errorOfferCode, errorMessage } = useCampaignContext()
  const { setFieldError } = useFormikContext()

  useEffect(() => {
    if (origin === `input`) {
      if (errorRedirectLink) {
        setFieldError(
          `offerCode`,
          <Trans
            t={t}
            i18nKey="common:offer_code.apply_other_product"
            components={{
              linkTo: <RedirectOtherProduct href={errorRedirectLink} />,
            }}
          />,
        )
      } else if (errorOfferCode) {
        setFieldError(`offerCode`, errorMessage ? t(errorMessage) : t(`common:offer_code.error_default`))
      }
    }
  }, [origin, errorRedirectLink, errorOfferCode, errorMessage, t])

  return (
    <Field
      component={InputOfferCode}
      name="offerCode"
      maxlength={30}
      required
      customRender={customRender}
      focusInput={focusInput}
    />
  )
}

InnerForm.propTypes = {
  customRender: PropTypes.func.isRequired,
  focusInput: PropTypes.bool,
}

const InnerFormMemo = memo(InnerForm)

const initialState = {
  offerCode: ``,
}

function validate (values) {
  return {
    offerCode: required(values.offerCode),
  }
}

export default function OfferCodeForm ({ customRender, focusInput = false }) {
  const { inputOfferCode } = useCampaignContext()

  function onSubmit (values) {
    inputOfferCode(values.offerCode)
  }

  return (
    <Form name="offer_code" initialValues={initialState} validate={validate} onSubmit={onSubmit}>
      <InnerFormMemo customRender={customRender} focusInput={focusInput} />
    </Form>
  )
}

OfferCodeForm.propTypes = {
  customRender: PropTypes.func,
  focusInput: PropTypes.bool,
}
