import React, { memo } from "react"
import styled from "styled-components"
import OfferCodeForm from "../../campaign/offerCodeForm.component"
import { Button } from "../../structure/theme/button.component"
import { Bold } from "../../structure/theme/styles.component"
import { Trans, useTranslation } from "../../translation/translate.component"

const OfferCodeTitle = styled.div`
  font-size: ${props => props.theme.fonts.sizes.x_large};
  margin-bottom: 20px;
`

const OfferZoneContainer = styled.div`
  text-align: center;
`

const OfferCodeBlock = styled.div`
  margin-right: 20px;
  width: 300px;
  text-align: left;
  input {
    height: 46px;
    border: 2px solid ${props => props.theme.colors.grey.g700};
    border-radius: 5px;
    background-color: white;
  }
`

const FieldAndButton = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`

const Submit = styled(Button)`
  width: auto;
`

function OfferCodeSection () {
  const { t } = useTranslation()

  return (
    <OfferZoneContainer>
      <OfferCodeForm
        customRender={({ field, infoError }) => (
          <label>
            <OfferCodeTitle>
              <Trans t={t} i18nKey="common:landing.offer_code_form" components={{ strong: <Bold /> }} />
            </OfferCodeTitle>
            <FieldAndButton>
              <OfferCodeBlock>
                {field} {infoError}
              </OfferCodeBlock>
              <Submit type="submit" data-testid="offer_code_form_submit">
                OK
              </Submit>
            </FieldAndButton>
          </label>
        )}
      />
    </OfferZoneContainer>
  )
}

export default memo(OfferCodeSection)
