import React, { useEffect, useMemo } from "react"
import { scrollTop } from "../../modules/navigation/navigation.utils"

export function scrollOnFocus (e, preventSticky = false) {
  const isMobile = `ontouchstart` in window || (window.DocumentTouch && document instanceof DocumentTouch) //  eslint-disable-line no-undef
  const element = e.target
  if (isMobile && element) {
    const offset = preventSticky ? 10 : 80
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    scrollTop(offsetPosition)
  }
}

export function focusElement (element) {
  if (element) {
    element.focus({ preventScroll: true })
  }
}

export function useFocus (nbFields, focusElementIndex) {
  const fields = useMemo(() => {
    return [...Array(nbFields).keys()].map(() => {
      const ref = React.createRef()

      return {
        ref,
        setFocus: () => focusElement(ref.current),
      }
    })
  }, [nbFields])

  useEffect(() => {
    if (typeof focusElementIndex === `number`) {
      fields[focusElementIndex].setFocus()
    }
  }, [fields, focusElementIndex])

  return fields
}

export function isHtmlElement (element) {
  // eslint-disable-next-line no-undef
  return element instanceof Element || element instanceof HTMLDocument
}
