import React, { createRef, useEffect } from "react"
import PropTypes from "prop-types"
import InfoAndError from "../../components/form/infoAndError.component"
import Label from "../../components/form/label.component"
import styled from "styled-components"
import { colors } from "../structure/theme"
import Notice from "../../components/form/notice.component"
import { focusElement, scrollOnFocus } from "../../components/form/form.utils"
import { usePageContext } from "../navigation/pageContext.provider"
import { useFormikContext } from "formik"

export const Input = styled.input`
  display: block;
  width: 100%;
  background-color: ${colors(`grey.g300`)};
  color: ${colors(`grey.g800`)};
  padding: 6px;
  line-height: 1.5;
  outline: none;
  border-style: solid;
  border-width: 1px;
  height: 100%;
  border-color: ${props => (props.hasError ? colors(`error`) : `grey`)};

  &:focus {
    outline: none;
  }
`

const InputContainer = styled.div`
  position: relative;
`

export default function InputOfferCode ({
  id,
  label = ``,
  notice = ``,
  renderInfo,
  field,
  customRender,
  focusInput = false,
}) {
  const { name, value } = field
  const { errors, touched, handleBlur, handleChange } = useFormikContext()
  const { preventSticky = false } = usePageContext()
  const inputRef = createRef()

  function onFocus (e) {
    scrollOnFocus(e, preventSticky)
  }

  useEffect(() => {
    if (!inputRef.current) {
      return
    }
    if (focusInput && inputRef.current !== document.activeElement) {
      focusElement(inputRef.current)
    } else if (!focusInput && inputRef.current === document.activeElement) {
      inputRef.current.blur()
    }
  }, [focusInput])

  const labelComponent = label && <Label htmlFor={id}>{label}</Label>
  const noticeComponent = <Notice text={notice} />
  const fieldComponent = (
    <InputContainer>
      <Input
        data-testid={`form_field_${name}`}
        name={name}
        value={value}
        id={id}
        type="text"
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
        hasError={errors[name]}
        isTouched={touched[name]}
        ref={inputRef}
      />
    </InputContainer>
  )

  const infoErrorComponent = <InfoAndError field={field} renderInfo={renderInfo} />

  const render = customRender ? (
    customRender({
      label: labelComponent,
      notice: noticeComponent,
      field: fieldComponent,
      infoError: infoErrorComponent,
    })
  ) : (
    <>
      {labelComponent}
      {noticeComponent}
      {fieldComponent}
      {infoErrorComponent}
    </>
  )

  return <div>{render}</div>
}

InputOfferCode.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  notice: PropTypes.string,
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  customRender: PropTypes.func,
  focusInput: PropTypes.bool,
}
